<template>
    <form @submit.prevent="submitForm" v-if="form">
        <mercur-card>
            <div class="row">
                <div class="col-6">
                    <mercur-input v-model="form.groupName" :class="getValidationClass($v, 'groupName')">
                        Customer Group Name
                        <template #note>
                            <span class="form-error" v-if="!$v.form.groupName.required">Group name is required</span>
                        </template>
                    </mercur-input>
                </div>
                <div class="col-6">
                    <div class="d-flex h-100 align-items-center">
                        <span class="ml-n1 mr-2">
                            <input type="radio" id="incl" :value="true" v-model="form.taxIncluded">
                            <label for="incl">Tax included</label>
                        </span>
                        <input type="radio" id="excl" :value="false" v-model="form.taxIncluded">
                        <label for="excl">Tax excluded</label>
                    </div>
                </div>
                <div class="col-6">
                    <pretty-select
                        class="mb-3"
                        placeholder="Tags"
                        :multiple="true"
                        :options="availableCustomerGroupTags"
                        :taggable="true"
                        v-model="form.tags"
                    ></pretty-select>
                </div>
                <div class="col-6">
                    <pretty-select
                        v-model="form.services"
                        :options="services"
                        :reduce="option => option.value"
                        :multiple="true"
                        label="label"
                        placeholder="Services"
                    ></pretty-select>
                </div>
                <div class="col-6">
                    <price-groups-selector :form="form"></price-groups-selector>
                </div>
            </div>
            <div class="text-right">
                <mercur-button class="btn" to="/customers/groups">Cancel</mercur-button>
                <mercur-button class="btn btn-primary" type="submit" :disabled="loading">Save</mercur-button>
            </div>
        </mercur-card>
    </form>
</template>

<script>
import FormMixin from '@/mixins/Form'
import PriceGroupsSelector from '@/components/elements/customers/PriceGroupsSelector'
import PrettySelect from '@/components/utils/PrettySelect'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'CustomerGroupForm',
    mixins: [ FormMixin ],
    components: { PriceGroupsSelector, PrettySelect },
    data () {
        return {
            availableCustomerGroupTags: ['PRO', 'MULTI_LANGUAGE'],
            services: [
                {
                    value: 'FREESHIPPING',
                    label: 'Free shipping',
                },
                {
                    value: 'FERESHIPPINGABOVE30EUROS',
                    label: 'Free shipping above 30 euros',
                },
                {
                    value: 'PREMIUMARTWORKCHECK',
                    label: 'Premium artwork check',
                },
            ],
        }
    },
    props: {
        action: {},
        form: {
            default: function () {
                return {
                    services: [],
                }
            },
        },
        message: {},
    },
    validations: {
        form: {
            groupName: {
                required,
            },
        },
    },
    methods: {
        submitForm () {
            this.submit(this.action, this.form).then(() => {
                this.$root.$emit('notification:global', {
                    message: this.message,
                })
                setTimeout(() => {
                    this.$router.push({
                        name: 'CustomersGroupsWrapper',
                    })
                }, 1000)
            })
        },
    },

}
</script>

<template>
    <pretty-select
        class="mb-3"
        label="name"
        placeholder="Shop Price Groups"
        :multiple="true"
        :options="items"
        :taggable="true"
        v-model="form.shopPriceGroups"
    ></pretty-select>
</template>

<script>
import ResourcesView from '@/views/ResourcesView'
import CONFIG from '@root/config'
import PrettySelect from '@/components/utils/PrettySelect'

export default {
    name: 'PriceGroupsSelector',
    components: { PrettySelect },
    mixins: [ ResourcesView ],
    props: {
        form: {
            default: function () {
                return {}
            },
        },
    },
    data () {
        return {
            url: CONFIG.API.ROUTES.CUSTOMERS.GROUPS.LIST_PRICE_GROUPS,
        }
    },
}
</script>
